<template>
  <div class="main">
    <div class="container">
      <div class="news_box">
        <div class="news_item">
          <div class="img">
            <img src="@/assets/images/banner.png" />
          </div>
          <div class="con">
            <div class="title">Who’s the next?</div>
            <div class="text">
              On December 16, an important part of the ECI Festival, one of the
              four major global innovation festivals hosted by iecia
              (International Digital Economy Innovation Organization), "2021 ECI
              awards international edgy award ceremony", was held at JW Marriott
              Hotel, tomorrow Plaza, Shanghai. The world's most influential
              experts and scholars, representatives of well-known enterprises,
              representatives of participating units, government and enterprise
              leaders Media partners attended and witnessed this highlight
              moment online and offline.
            </div>
            <div class="readmore" @click="toDetail">READ MORE</div>
            <div class="readmore2" @click="toDetail">BUY</div>
          </div>
        </div>
        <div class="news_item">
          <div class="img">
            <img src="@/assets/images/banner.png" />
          </div>
          <div class="con">
            <div class="title">Who’s the next?</div>
            <div class="text">
              On December 16, an important part of the ECI Festival, one of the
              four major global innovation festivals hosted by iecia
              (International Digital Economy Innovation Organization), "2021 ECI
              awards international edgy award ceremony", was held at JW Marriott
              Hotel, tomorrow Plaza, Shanghai. The world's most influential
              experts and scholars, representatives of well-known enterprises,
              representatives of participating units, government and enterprise
              leaders Media partners attended and witnessed this highlight
              moment online and offline.
            </div>
            <div class="readmore" @click="toDetail">READ MORE</div>
            <div class="readmore2" @click="toDetail">BUY</div>
          </div>
        </div>
        <div class="news_item">
          <div class="img">
            <img src="@/assets/images/banner.png" />
          </div>
          <div class="con">
            <div class="title">Who’s the next?</div>
            <div class="text">
              On December 16, an important part of the ECI Festival, one of the
              four major global innovation festivals hosted by iecia
              (International Digital Economy Innovation Organization), "2021 ECI
              awards international edgy award ceremony", was held at JW Marriott
              Hotel, tomorrow Plaza, Shanghai. The world's most influential
              experts and scholars, representatives of well-known enterprises,
              representatives of participating units, government and enterprise
              leaders Media partners attended and witnessed this highlight
              moment online and offline.
            </div>
            <div class="readmore" @click="toDetail">READ MORE</div>
            <div class="readmore2" @click="toDetail">BUY</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    toDetail() {
      this.$router.push({
        path: '/Academy/MarTechDetail',
        query: { id: '1' },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/news.scss';
</style>
